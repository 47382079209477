import React from 'react'


const carList = [
    {
      id: 1,
      name: "Maria Laura",
      info: "Volunteering at Fundación Ecológica Maguá was enriching. Manuel, the founder, generously shares knowledge. The team's dedication to the environment is evident. The experience was enjoyable, promising, and educational. I highly recommend volunteering with them.",
    },
    {
      id: 2,
      name: "Julia Ford",
      info: "Volunteering with Fundacion Ecologica Maguá was the most 'real' experience I have ever had, they are doing so many great things and it felt so rewarding to be part of their projects. I enjoyed every minute of my stay and looking foward to come back soon",
    },
    {
      id: 3,
      name: "Rosario",
     info: "Fundación Ecológica Maguá welcomed my son with limited mobility warmly, showcasing their marine lab and explaining our country's ecosystem. He enjoyed the coral restoration project and received a certificate. Grateful for Manuel and his team's care and efforts."
    },
    { 
      id: 4,
      name: "John Carter",
     info: "we made 'coral cakes ' with micro fragments of corals that were rescued and treated for a coral disease, we placed them in the ocean nursery where they will fuse into a mature coral colony and reproduce. So proud to be a 'coral dad'"
    },
  ];

const Test = () => {
    return(
        <div>
       {carList.filter((person) => person.id <=3).map((person)=>
        <p key={person.id}> {person.name}</p>
       )}
        </div>
    )
}

export default Test