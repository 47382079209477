import { Link, useNavigate } from "react-router-dom"
import { useState } from "react"
import axios from "axios"

const Register = () => {

    const [inputs,setInputs] = useState({
        username:"",
        email:"",
        password:""
    })

    const navigate = useNavigate(); // Initialize the navigate function

    const handleChange = (e)=> {
        setInputs((prev)=> ({...prev, [e.target.name]: e.target.value}));
    }

    const [err,SetErr] = useState(null)

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
          const res= await axios.post("/auth/register", inputs);
          navigate("/login")
        } catch (err) {
         SetErr(err.response.data)
        }
      };
        // inputs müssen an Backend geschickt werden
    


    return(
        <div>
              <div className="auth">
      <h1>Register</h1>
      <form>
        <input
          required
          type="text"
          placeholder="username"
          name="username"
          onChange={handleChange}
        />
        <input
          required
          type="email"
          placeholder="email"
          name="email"
          onChange={handleChange}
        />
        <input
          required
          type="password"
          placeholder="password"
          name="password"
          onChange={handleChange}
        />
        <button onClick={handleSubmit}>Register</button>
        {err && <p> {err} </p>}
        <span>
          Do you have an account? <Link to="/">Home</Link>
        </span>
      </form>
    </div>
        </div>
    )
}

export default Register