import React from 'react'
import { useContext } from 'react'
import './projecrsCollection.scss'
import Header from '../../components/blogHeader/blogHeader'
import { AuthContext } from '../../context/authContext'
import { Route, Routes, useLocation } from 'react-router-dom'
import ProjectList from '../../components/ProjectList/ProjectList'


const blog = () => {
  const { currentUser, setCurrentUser, logout } = useContext(AuthContext);


  return (
    <Routes>
      <Route index element={
        <main>
              <div className='userFeatures'> {currentUser? <><a className='logout' onClick={logout}> Logout</a>
             <a href='/projects/create'> Create new Post</a></> :  <a href="/login"> Login</a> }
            </div>
          <Header />
          <ProjectList/>
        
        </main>
      } />



    </Routes>

  )
}

export default blog