import React, { useState, useEffect } from 'react';
import { useLocation, Link } from 'react-router-dom';
import axios from 'axios';
import ProjectFeature from '../Projectfeatures/ProjectFeature'
import './projectsMain.scss';

const ProjectMain = () => {
  const [posts, setPosts] = useState([]);
  const cat = useLocation().search;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get(`http://ecomagua.org:8080/api/posts/`);
        setPosts(res.data);
      } catch (err) {
        console.log(err);
      }
    };
    fetchData();
  }, [cat]);

  const truncateText = (text, maxLength) => {
    if (!text) return '';
    return text.length > maxLength ? text.slice(0, maxLength) + '...' : text;
  };



  return (
    <div className="projectsContainer">
    <h2 className='mHeadline'> Our Projects</h2>
    <div className="postsM">
    {posts.slice(0, 3).map((post) => (
        <ProjectFeature
          key={post.id}
          title={post.title}
          description={truncateText(post.desc, 400)}
          image={post.img ? `../upload/${post.img}` : ''}
          link={`/post/${post.id}`}
        />
      ))}
       <div className="see-all-projects">
        <Link to="/projects" className="see-all-link">See all of our Projects</Link>
      </div>
    </div>
  </div>
  );
};

export default ProjectMain;