import { React, useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import axios from "axios";
import '../ProjectList/projectList.scss';

const ProjectList = () => {
  const [posts, setPosts] = useState([]);
  const cat = useLocation().search;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get(`http://ecomagua.org:8080/api/posts/${cat}`);
        setPosts(res.data);
      } catch (err) {
        console.log(err);
      }
    };
    fetchData();
  }, [cat]);



  // Funktion zum Kürzen des Texts auf 300 Zeichen
  const truncateText = (text, maxLength) => {
    if (!text) return '';
    return text.length > maxLength ? text.slice(0, maxLength) + '...' : text;
  };

  return (
    <div className="home">
      <div className="posts">
        {posts.map((post) => (
          <div className="post" key={post.id}>
            <div className="content">
              <Link className="link" to={`/post/${post.id}`}>
                <h1 className='head__title'>{post.title}</h1>
                {post.img &&  <img src={`../upload/${post?.img}`} alt="" />}
              </Link>
              <p dangerouslySetInnerHTML={{ __html: truncateText(post.desc, 500) }}></p>
         
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProjectList;
